@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueBold.woff2') format('woff2'),
        url('BebasNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueRegular.woff2') format('woff2'),
        url('BebasNeueRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueLight.woff2') format('woff2'),
        url('BebasNeueLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueLight.woff2') format('woff2'),
        url('BebasNeueLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueBold.woff2') format('woff2'),
        url('BebasNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('BebasNeueBook.woff2') format('woff2'),
        url('BebasNeueBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeue-Thin.woff2') format('woff2'),
        url('BebasNeue-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueBold.woff2') format('woff2'),
        url('BebasNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('BebasNeueBook.woff2') format('woff2'),
        url('BebasNeueBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueLight.woff2') format('woff2'),
        url('BebasNeueLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeue-Thin.woff2') format('woff2'),
        url('BebasNeue-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueRegular.woff2') format('woff2'),
        url('BebasNeueRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('BebasNeueBook.woff2') format('woff2'),
        url('BebasNeueBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeueRegular.woff2') format('woff2'),
        url('BebasNeueRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('BebasNeue-Thin.woff2') format('woff2'),
        url('BebasNeue-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

