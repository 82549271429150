/* autoprefixer grid: autoplace */

:root {
    --fontFamilyBody: 'Montserrat', sans-serif;
    --fontFamilyHeadings: 'Montserrat', sans-serif;
    --fontFamilySecond: 'Bebas Neue', sans-serif;

    --invalidColor: red;
    --primaryColor: #1976d2;
}

html, body {
    font-family: var(--fontFamilyBody);
    min-height: 100%;
    max-width: 100vw;
    height: 100%;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    -webkit-tap-highlight-color: transparent;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

button {
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
    font-size: 16px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}